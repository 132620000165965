import React, { useState, useEffect } from 'react';
import $ from 'jquery'; 
import M from 'materialize-css';
import { Container, Col, Modal, RadioGroup, TextInput } from 'react-materialize';
import styled from 'styled-components';
import { SIZE, LABEL, CATEGORY, COLOR, FONT_FAMILY, URL } from '../../utils/constants.js';
import { PICTURES_BIRTH } from '../../utils/pictures_birth.js'
import { PICTURES_BABYSHOWER } from '../../utils/pictures_babyshower.js'
import { PICTURES_CELEBRATIONS } from '../../utils/pictures_celebrations.js'
import { PICTURES_ROMANTIC } from '../../utils/pictures_romantic.js'
import { PICTURES_BAPTISM } from '../../utils/pictures_baptism.js'
import { PICTURES_WEDDING } from '../../utils/pictures_wedding.js'
import {
    BIRTH_FILTER_MODAL,
    getBirthFiltered,
    getSearchResult
} from '../../utils/managerthematic.js';
import Row from '../organism/row.jsx';
import Path from '../molecules/path.jsx';
import Button from '../atoms/button.jsx';
import Banner from '../organism/banner.jsx';
import Divider from '../atoms/divider';
import FixedButtons from '../molecules/fixedbuttons.jsx';
import TextButton from '../atoms/textbutton.jsx';
import Photos from '../organism/photos.jsx';
import Lightbox from "yet-another-react-lightbox";
import Counter from "yet-another-react-lightbox/plugins/counter";
import Share from "yet-another-react-lightbox/plugins/share";
import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/counter.css";
import "yet-another-react-lightbox/plugins/captions.css";
import { Label } from '../atoms/label.jsx';



import PathWapp from '../../icons/ic_wapp.svg';
import PathFb from '../../icons/ic_fb_colored.svg';
import PathTikTok from '../../icons/ic_tiktok.svg';
import PathInsta from '../../icons/ic_instagram_colored.svg';

import PathIcSarch from '../../icons/ic_search.svg'


import PathPhotoBirth01 from '../../images_categories/birth/img_008.jpg';
import PathPhotoBirth02 from '../../images_categories/birth/img_036_004.jpg';
import PathPhotoBirth03 from '../../images_categories/birth0214/img_034_001.jpg';

import PathPhotoBabyshower01 from '../../images_categories/babyshower/img_001_01.jpg';
import PathPhotoBabyshower02 from '../../images_categories/babyshower/img_005_003.jpg';
import PathPhotoBabyshower03 from '../../images_categories/babyshower/img_004_02.jpg';

import PathPhotoCelebration01 from '../../images_categories/celebrations/img_001_vertical.jpg';
import PathPhotoCelebration02 from '../../images_categories/celebrations/img_002_vertical.jpg';
import PathPhotoCelebration03 from '../../images_categories/celebrations/img_005_01.jpg';

import PathPhotoRomantic01 from '../../images_categories/romantic/img_002_004_thumbnail.jpg';
import PathPhotoRomantic02 from '../../images_categories/romantic/img_001_002_thumbnail.jpg';
import PathPhotoRomantic03 from '../../images_categories/romantic/img_002_005_thumbnail.jpg';

import PathPhotoBaptism01 from '../../images_categories/baptism/img_001.jpg';
import PathPhotoBaptism02 from '../../images_categories/baptism/img_002.jpg';
import PathPhotoBaptism03 from '../../images_categories/baptism/img_003_00.jpg';

import PathPhotoWedding01 from '../../images/img_photo_wedding_01.jpg';
import PathPhotoWedding02 from '../../images_categories/wedding/img_001.jpg';
import PathPhotoWedding03 from '../../images_categories/wedding/img_002_02_vertical.jpg';


const RowClickable = styled(Row)`
    &:hover {
        cursor: pointer;
    }
`

const RadioContent = styled.div`
    [type="radio"] {
        &:checked+span {
            &::before {
                border: 2px solid ${COLOR.ferra};
            }
            &:after {
                background-color: ${COLOR.ferra};
                border: 1px solid ${COLOR.ferra};
            }
        }
    }
    label {
        color: ${COLOR.ferra};
        font-size: 36px;
        width: 100%;
        display: inline-block;
    }
`

var listBirth = PICTURES_BIRTH
const listBabyshower = PICTURES_BABYSHOWER
const listCelebrations = PICTURES_CELEBRATIONS
const listRomantic = PICTURES_ROMANTIC
const listBaptism = PICTURES_BAPTISM
const listWedding = PICTURES_WEDDING


const BodyThematic = () => {
    const [searchValue, setSearchValue] = useState('');

    const [birthPreviewOpen, setBirthPreviewOpen] = useState(false);
    const [celebrationsPreviewOpen, setCelebrationsPreviewOpen] = useState(false);
    const [babyShowerPreviewOpen, setBabyShowerPreviewOpen] = useState(false);
    const [romanticPreviewOpen, setRomanticPreviewOpen] = useState(false);
    const [baptismPreviewOpen, setBaptismPreviewOpen] = useState(false);
    const [weddingPreviewOpen, setWeddingPreviewOpen] = useState(false);
    const [birthFilterModal, setBirthFilterModal] = useState();
    const [selectedBirthFilter, setSelectedBirthFilter] = useState(BIRTH_FILTER_MODAL.filterOptions[0].value);

    const [descriptionMaxLines, setDescriptionMaxLines] = useState(3);
    const [descriptionTextAlign, setDescriptionTextAlign] = useState("end"); //"start" | "end" | "center"

    useEffect(() => {
        $(window).on('load', function() {
            // ready method is deprecated
            var birthModal = M.Modal.getInstance($(`#${BIRTH_FILTER_MODAL.id}`)); // Init modal
            setBirthFilterModal(birthModal);
        });
    }, [])

    const handleBirthFilter = (selectedValue) => {
        birthFilterModal.close()
        listBirth = getBirthFiltered(selectedValue)
        setBirthPreviewOpen(true)
    }

    const handleSearchOnkeyDown = (event) => {
        if (searchValue.length < 3) return

        if (event.key === 'Enter') {
            listBirth = getSearchResult(searchValue)
            if (listBirth.length === 0) {
                alert("No se encontraron resultados")
            } else {
                setBirthPreviewOpen(true)
            }
        }
    }

    return(
        <div>
            <Banner title={LABEL.thematics}/>
  
            <Container>
                <FixedButtons
                    items={[
                        { href: URL.whatsapp, ic_path: PathWapp, ic_size: '50px' },
                        { href: URL.facebook, ic_path: PathFb, ic_size: '50px' },
                        { href: URL.tiktok, ic_path: PathTikTok, ic_size: '50px' },
                        { href: URL.instagram, ic_path: PathInsta, ic_size: '50px' }
                        ]}
                    aligment='right'/>
                <Row
                    margin='0 0'>
                    <Path 
                        endpoint={LABEL.thematics}
                        margin='20px 0' />
                </Row>
                    
                <Row className='valign-wrapper' margin='30px 0'>
                    <TextInput
                        placeholder='Buscar temática'
                        onChange={ (event) => { setSearchValue(event.target.value) } }
                        onKeyDown={ (event) => { handleSearchOnkeyDown(event) } }/>
                </Row>

                <RowClickable className='valign-wrapper' onClick={ () => birthFilterModal.open() }>
                    <Col s={12} m={12} l={2} xl={2}>
                        <Label
                            text_size={SIZE.display_top}
                            text_color={COLOR.secondary}
                            text_align='center'
                            font_family={FONT_FAMILY.unicorns}> 
                            { CATEGORY.birth.title } 
                        </Label>
                    </Col>
                    <Col s={5} m={6} l={4} xl={4}>
                        <Photos
                            photo_01_src={ PathPhotoBirth01 }
                            photo_01_rotation='-5deg'
                            photo_02_src={ PathPhotoBirth02 }
                            photo_02_rotation='2deg'
                            photo_03_src={ PathPhotoBirth03 }
                            photo_03_rotation='10deg'
                            margin='30px 0'/>
                    </Col>
                    <Col s={7} m={4} l={3} xl={2}></Col>
                </RowClickable>

                {/* ****** MODAL ****** */}
                <Modal
                    id={ BIRTH_FILTER_MODAL.id }
                    header={ BIRTH_FILTER_MODAL.title }
                    fixedFooter={ false } //If true breaks DatePickers
                    actions={[
                    <Button 
                        onClick={() => { handleBirthFilter(selectedBirthFilter) }}
                        width='120px'
                        height='46px'
                        bg_color={COLOR.primary}
                        float='right'>
                        <Label
                            text_color={ COLOR.ferra }
                            text_size={SIZE.headline}
                            font_family={FONT_FAMILY.unicorns}
                            margin='auto'>
                            Aplicar
                        </Label>
                    </Button>
                    ]}>
                    <Row margin='30px 0 0 0'>
                        <RadioContent>
                        <RadioGroup
                            label="Birth-filter"
                            name="size"
                            className='valign-wrapper'
                            withGap={ true }
                            options={ BIRTH_FILTER_MODAL.filterOptions }
                            value={ selectedBirthFilter }
                            onChange={(event) => setSelectedBirthFilter(event.target.value)}
                            />
                        </RadioContent>
                    </Row>
                </Modal>

                <Lightbox
                    open={birthPreviewOpen}
                    plugins={ [Counter, Share, Captions] }
                    captions={ { descriptionTextAlign, descriptionMaxLines } }
                    counter={ { container: { style: { top: "unset", bottom: 0 } } } }
                    slides={ listBirth }
                    close={() => setBirthPreviewOpen(false)}
                />

                <Divider/>


                <RowClickable className='valign-wrapper' onClick={ () => setBabyShowerPreviewOpen(true) }>
                    <Col s={12} m={12} l={2} xl={2}>
                        <Label
                            text_size={SIZE.display_top}
                            text_color={COLOR.secondary}
                            text_align='center'
                            font_family={FONT_FAMILY.unicorns}
                            margin='40px 0 0 0'> 
                            { CATEGORY.babyshower.title } 
                        </Label>
                    </Col>
                    <Col s={5} m={6} l={4} xl={4}>
                        <Photos
                            photo_01_src={ PathPhotoBabyshower01 }
                            photo_01_rotation='-10deg'
                            photo_02_src={ PathPhotoBabyshower02 }
                            photo_02_rotation='-3deg'
                            photo_03_src={ PathPhotoBabyshower03 }
                            photo_03_rotation='8deg'
                            margin='50px 0'/>
                    </Col>
                    <Col s={7} m={4} l={3} xl={2}></Col>
                </RowClickable>

                <Lightbox
                    open={babyShowerPreviewOpen}
                    plugins={ [Counter, Share, Captions] }
                    captions={ { descriptionTextAlign, descriptionMaxLines } }
                    counter={ { container: { style: { top: "unset", bottom: 0 } } } }
                    slides={ listBabyshower }
                    close={() => setBabyShowerPreviewOpen(false)}
                />
                  
                <Divider/>


                <RowClickable className='valign-wrapper' onClick={ () => setCelebrationsPreviewOpen(true) }>
                    <Col s={12} m={12} l={2} xl={2}>
                        <Label
                            text_size={SIZE.display_top}
                            text_color={COLOR.secondary}
                            text_align='center'
                            font_family={FONT_FAMILY.unicorns}> 
                            { CATEGORY.celebrations.title } 
                        </Label>
                    </Col>
                    <Col s={5} m={6} l={4} xl={4}>
                        <Photos
                            photo_01_src={PathPhotoCelebration02}
                            photo_01_rotation='-10deg'
                            photo_02_src={PathPhotoCelebration01}
                            photo_02_rotation='-2deg'
                            photo_03_src={PathPhotoCelebration03}
                            photo_03_rotation='4deg'
                            margin='30px 0'/>
                    </Col>
                    <Col s={7} m={4} l={3} xl={2}></Col>
                </RowClickable>
                <Lightbox
                    open={celebrationsPreviewOpen}
                    plugins={ [Counter, Share, Captions] }
                    captions={ { descriptionTextAlign, descriptionMaxLines } }
                    counter={ { container: { style: { top: "unset", bottom: 0 } } } }
                    slides={ listCelebrations }
                    close={() => setCelebrationsPreviewOpen(false)}
                />

                <Divider/>


                <RowClickable className='valign-wrapper' onClick={ () => setRomanticPreviewOpen(true) }>
                    <Col s={12} m={12} l={2} xl={2}>
                        <Label
                            text_size={SIZE.display_top}
                            text_color={COLOR.secondary}
                            text_align='center'
                            font_family={FONT_FAMILY.unicorns}
                            margin='40px 0 0 0'> 
                            { CATEGORY.romantic.title } 
                        </Label>
                    </Col>
                    <Col s={5} m={6} l={4} xl={4}>
                        <Photos
                            photo_01_src={ PathPhotoRomantic01 }
                            photo_01_rotation='-12deg'
                            photo_02_src={ PathPhotoRomantic02 }
                            photo_02_rotation='-10deg'
                            photo_03_src={ PathPhotoRomantic03 }
                            photo_03_rotation='8deg'
                            margin='50px 0'/>
                    </Col>
                    <Col s={7} m={4} l={3} xl={2}></Col>
                </RowClickable>

                <Lightbox
                    open={ romanticPreviewOpen }
                    plugins={ [Counter, Share, Captions] }
                    captions={ { descriptionTextAlign, descriptionMaxLines } }
                    counter={ { container: { style: { top: "unset", bottom: 0 } } } }
                    slides={ listRomantic }
                    close={() => setRomanticPreviewOpen(false)}
                />

                <Divider/>
                

                <RowClickable className='valign-wrapper' onClick={ () => setBaptismPreviewOpen(true) }>
                    <Col s={12} m={12} l={2} xl={2}>
                        <Label
                            text_size={SIZE.display_top}
                            text_color={COLOR.secondary}
                            text_align='center'
                            font_family={FONT_FAMILY.unicorns}
                            margin='40px 0 0 0'> 
                            { CATEGORY.baptism.title } 
                        </Label>
                    </Col>
                    <Col s={5} m={6} l={4} xl={4}>
                        <Photos
                            photo_01_src={ PathPhotoBaptism02 }
                            photo_01_rotation='-5deg'
                            photo_02_src={ PathPhotoBaptism03 }
                            photo_02_rotation='4deg'
                            photo_03_src={ PathPhotoBaptism01 }
                            photo_03_rotation='4deg'
                            margin='50px 0'/>
                    </Col>
                    <Col s={7} m={4} l={3} xl={2}></Col>
                </RowClickable>

                <Lightbox
                    open={ baptismPreviewOpen }
                    plugins={ [Counter, Share, Captions] }
                    captions={ { descriptionTextAlign, descriptionMaxLines } }
                    counter={ { container: { style: { top: "unset", bottom: 0 } } } }
                    slides={ listBaptism }
                    close={() => setBaptismPreviewOpen(false)}
                />

                <Divider/>

                <RowClickable className='valign-wrapper' onClick={ () => setWeddingPreviewOpen(true) }>
                    <Col s={12} m={12} l={2} xl={2}>
                        <Label
                            text_size={SIZE.display_top}
                            text_color={COLOR.secondary}
                            text_align='center'
                            font_family={FONT_FAMILY.unicorns}
                            margin='40px 0 0 0'> 
                            { CATEGORY.wedding.title } 
                        </Label>
                    </Col>
                    <Col s={5} m={6} l={4} xl={4}>
                        <Photos
                            photo_01_src={PathPhotoWedding01}
                            photo_01_rotation='-6deg'
                            photo_02_src={PathPhotoWedding02}
                            photo_02_rotation='1deg'
                            photo_03_src={ PathPhotoWedding03 }
                            photo_03_rotation='8deg'
                            margin='50px 0'/>
                    </Col>
                    <Col s={7} m={4} l={3} xl={2}></Col>
                </RowClickable>

                <Lightbox
                    open={ weddingPreviewOpen }
                    plugins={ [Counter, Share, Captions] }
                    captions={ { descriptionTextAlign, descriptionMaxLines } }
                    counter={ { container: { style: { top: "unset", bottom: 0 } } } }
                    slides={ listWedding }
                    close={() => setWeddingPreviewOpen(false)}
                />
  
            </Container>
        </div>
     );
}

export default BodyThematic;